import { APP_PREFIX_PATH } from 'configs/AppConfig';
import {
  DashboardOutlined,
  FieldTimeOutlined,
  TeamOutlined,
  UserOutlined,
  GoldOutlined,
  MonitorOutlined,
  SnippetsOutlined,
  ClearOutlined,
  ReconciliationOutlined,
  ControlOutlined,
  FileDoneOutlined,
  ShoppingCartOutlined,
  DeliveredProcedureOutlined,
  BgColorsOutlined,
  GiftOutlined,
  MergeCellsOutlined,
  SplitCellsOutlined,
  SolutionOutlined,
  CompressOutlined,
  RadiusUprightOutlined,
} from '@ant-design/icons';

const dashBoardNavTree = [
  {
    key: 'management',
    path: '',
    title: 'sidenav.pages.management',
    breadcrumb: false,
    submenu: [
      {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: 'sidenav.pages.dashboard',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'timeline',
        path: `${APP_PREFIX_PATH}/timeline`,
        title: 'sidenav.pages.timeline',
        icon: FieldTimeOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'generalSupervisor',
        path: `${APP_PREFIX_PATH}/supervisor-geral`,
        title: 'sidenav.pages.generalsupervisor',
        icon: ReconciliationOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'users',
        title: 'sidenav.pages.users',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'userList',
            path: `${APP_PREFIX_PATH}/lista-de-usuarios`,
            title: 'sidenav.pages.userlist',
            icon: UserOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'rolesPermissions',
            path: `${APP_PREFIX_PATH}/cargos-e-permissoes`,
            title: 'sidenav.pages.roles',
            icon: ControlOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    key: 'logistics',
    path: '',
    title: 'sidenav.pages.logistics',
    breadcrumb: false,
    submenu: [
      {
        key: 'materialReceiving',
        path: `${APP_PREFIX_PATH}/recebimento-de-material`,
        title: 'sidenav.pages.orders',
        icon: DeliveredProcedureOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'orderStatus',
        path: `${APP_PREFIX_PATH}/status-pedido`,
        title: 'sidenav.pages.orderstatus',
        icon: ShoppingCartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'stamping',
        path: `${APP_PREFIX_PATH}/estampagem`,
        title: 'sidenav.pages.stamping',
        icon: CompressOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'conference',
        path: `${APP_PREFIX_PATH}/conferencia`,
        title: 'sidenav.pages.conference',
        icon: MonitorOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'anodizing',
    path: '',
    title: 'sidenav.pages.anodizing',

    breadcrumb: false,
    submenu: [
      {
        key: 'supervisor-anodizing',
        path: `${APP_PREFIX_PATH}/supervisor-anodizacao`,
        title: 'sidenav.pages.supervisor',
        icon: SolutionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'polishing',
        path: `${APP_PREFIX_PATH}/polimento`,
        title: 'sidenav.pages.polishing',
        icon: RadiusUprightOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'brushing',
        path: `${APP_PREFIX_PATH}/escovacao`,
        title: 'sidenav.pages.brushing',
        icon: ClearOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'assembly',
        path: `${APP_PREFIX_PATH}/montagem`,
        title: 'sidenav.pages.assembly',
        icon: MergeCellsOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'tanks',
        path: `${APP_PREFIX_PATH}/tanques`,
        title: 'sidenav.pages.tanks',
        icon: BgColorsOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'disassembly',
        path: `${APP_PREFIX_PATH}/desmontagem`,
        title: 'sidenav.pages.disassembly',
        icon: SplitCellsOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'packaging-anodizing',
        path: `${APP_PREFIX_PATH}/embalagem`,
        title: 'sidenav.pages.packaging',
        icon: GiftOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'painting',
    path: '',
    title: 'sidenav.pages.painting',
    breadcrumb: false,
    submenu: [
      {
        key: 'supervisor-painting',
        path: `${APP_PREFIX_PATH}/supervisor-pintura`,
        title: 'sidenav.pages.supervisor',
        icon: SolutionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'checklist',
        path: `${APP_PREFIX_PATH}/checklist`,
        title: 'sidenav.pages.checklist',
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'deplating',
        path: `${APP_PREFIX_PATH}/desplacagem`,
        title: 'sidenav.pages.deplating',
        icon: SnippetsOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'painting',
        path: `${APP_PREFIX_PATH}/pintura/montagem`,
        title: 'sidenav.pages.assembly',
        icon: MergeCellsOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'sublimation',
        path: `${APP_PREFIX_PATH}/sublimacao`,
        title: 'sidenav.pages.sublimation',
        icon: GoldOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'paintPackaging',
        path: `${APP_PREFIX_PATH}/pintura/embalagem`,
        title: 'sidenav.pages.packaging',
        icon: GiftOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
