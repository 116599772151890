import React, { Component } from 'react'

export class Icon extends Component {
	render() {
		const { type, className, size } = this.props;

		const style = size ? { fontSize: size } : {}
		return (
			<>{React.createElement(type, { className: className, style: style })}</>
		)
	}
}

export default Icon
